import Swiper from "swiper";
import { Mousewheel, Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";

class CardsSlider {
  constructor(el) {
    this.el = el;
    this.swiper = this.el.querySelector(".swiper");

    if (this.swiper) {
      new Swiper(this.swiper, {
        autoHeight: false,
        spaceBetween: 20,
        slidesPerView: 1.2,
        loop: false,
        grabCursor: true,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        direction: "horizontal",
        mousewheel: {
          enabled: true,
          forceToAxis: true,
        },

        breakpoints: {
          667: {
            slidesPerView: 2.2,
          },
          768: {
            spaceBetween: 15,
            slidesPerView: 1.7,
          },
          992: {
            slidesPerView: 2.2,
            spaceBetween: 15,
            slidesOffsetAfter: 40,
            slidesOffsetBefore: 40,
          },
          1536: {
            spaceBetween: 15,
            slidesPerView: 3.3,
          },
        },
        navigation: {
          prevEl: this.el.querySelector(".swiper-arrows .swiper-arrows__prev"),
          nextEl: this.el.querySelector(".swiper-arrows .swiper-arrows__next"),
        },
        modules: [Navigation, Mousewheel],
      });
    }
  }
}

function initCardsSliders() {
  const sliders = document.querySelectorAll(".js-cards-slider");
  for (let slider of sliders) {
    new CardsSlider(slider);
  }
}

initCardsSliders();
