class SideMenu {
  el;
  buttonOpen;
  buttonClose;
  buttonSubitem;
  subMenu;


  constructor(sideMenu) {
    this.el = sideMenu;
    this.buttonOpen = document.querySelector(".button-side-menu");
    this.buttonClose = this.el.querySelector(".button-close");
    this.buttonSubitem = this.el.querySelector(".js-menu-item-btn");
    this.subMenu = this.el.querySelector(".menu-item-has-children .sub-menu");
    this.body = document.querySelector('body');
    
    if (this.buttonOpen) {
      this.buttonOpen.addEventListener("click", this.open.bind(this));
    }
    if (this.buttonClose) {
      this.buttonClose.addEventListener("click", this.close.bind(this));
    }
    if (this.buttonSubitem) {
      this.buttonSubitem.addEventListener("click", this.toggleSubitems.bind(this));
    }
  }

  open() {
    this.el.classList.add("open");
    this.body.style.overflow = 'hidden';
    this.buttonOpen.setAttribute("aria-expanded", true);
    this.buttonClose.style.display = "block";
    this.buttonClose.setAttribute("aria-expanded", false);
  }

  close() {
    this.el.classList.remove("open");
    this.body.style.overflow = 'auto';
    this.buttonOpen.setAttribute("aria-expanded", false);
    this.buttonClose.setAttribute("aria-expanded", true);
  }

  toggleSubitems(event) {
    const button = event.currentTarget;
    const isExpanded = button.getAttribute("aria-expanded") === "true";
    button.setAttribute("aria-expanded", !isExpanded);
    button.classList.toggle("open", !isExpanded);
    this.subMenu.classList.toggle("open", !isExpanded);
  }
}

const sideMenu = document.querySelector(".js-side-menu");
if (sideMenu) {
  new SideMenu(sideMenu);
}
