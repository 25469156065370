class AccordionItems {
    constructor(el) {
      this.el = el;
      this.elItems = this.el.querySelectorAll(".accordion-item");
      for (let item of this.elItems) {
        new AccordionItem(item, this.el.dataset.type);
      }
    }
  }
  
  class AccordionItem {
    constructor(el, type) {
      this.el = el;
      this.type = type;
      this.elButton = this.el.querySelector(".accordion-item-button");
      this.elPanel = this.el.querySelector(".accordion-item-panel");
  
      this.elButton.addEventListener(
        "click",
        function (ev) {
          let accordionExpanded =
            this.elButton.getAttribute("aria-expanded") === "true";
          let x = window.matchMedia("(max-width: 992px)");
          if (!accordionExpanded) {
            this.elButton.setAttribute("aria-expanded", true);
            this.elPanel.style.height = this.elPanel.scrollHeight + "px";
            this.elPanel.style.transition = "height .4s ease-in-out";
            this.el.classList.add("expanded");

          } else {
            this.elButton.setAttribute("aria-expanded", false);
            this.elPanel.style.height = "0px";
            this.elPanel.style.transition = "height .3s ease-in-out";
            this.el.classList.remove("expanded");
          }
        }.bind(this),
        false
      );
    }
  }
  
  const initAccordions = () => {
    const accordions = document.querySelectorAll(".accordion");
  
    if (accordions) {
      for (let accordion of accordions) {
        new AccordionItems(accordion);
      }
    }
  };
  
  initAccordions();
  